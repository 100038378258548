import * as React from "react"
import { graphql } from 'gatsby'

import Meta from '../../components/meta'
import Layout from '../../components/layout'
import ContentRow from '../../components/contentRow'


const Highlight = ({ data }) => (
    <Layout green={true}>
        <Meta
            title="The Best Design Software"
            description="We highlight the best web-based design tools"
            url="/highlight"
        />

        <ContentRow
            colorFlag="green"
            headline="Tool Highlight"
            description="This month, we highlight the best design tools that run entirely on your web browser.
            All of the software showcased here just work seamlessly on the web and on all operating systems!"
        />

        <ContentRow
            colorFlag="purple"
            headline="The best graphic design software for beginners"
            description="Canva is the best tool for beginners! It offers a fast and easy way to do graphic design and create posters, logos, infographics, social media posts, or flyers."
            link="https://www.canva.com/?ref=bestdesignsoftware.io"
            image={'fluid' in data.canvaImage.childImageSharp ? data.canvaImage.childImageSharp.fluid : null}
            imagAlt="Screenshot of the design tool Canva"
        />

        <ContentRow
            colorFlag="green"
            headline="The best screen design software for beginners"
            description="Uizard is the easiest design tool for creating beautiful and professional apps and websites! Its ease and speed also makes it the ideal tool for UX designers that need to quickly design interfaces."
            link="https://uizard.io/?ref=bestdesignsoftware.io"
            image={'fluid' in data.uizardImage.childImageSharp ? data.uizardImage.childImageSharp.fluid : null}
            imagAlt="Screenshot of the design tool Uizard"
        />

        <ContentRow
            colorFlag="purple"
            headline="The best screen design software for professional designers"
            description="Figma has all the features needed for professional UI designers! This tool is the best option for the pros that need advanced vector design features and aren't afraid to spend the time it takes to get pixel-perfect results."
            link="https://www.figma.com/?ref=bestdesignsoftware.io"
            image={'fluid' in data.figmaImage.childImageSharp ? data.figmaImage.childImageSharp.fluid : null}
            imagAlt="Screenshot of the design tool Figma"
        />

        <ContentRow
            colorFlag="green"
            headline="The best software for designing presentations"
            description="Pitch is the best solution for designing slides and decks. It's a modern alternative to Google Docs packed with beautiful templates to start from."
            link="https://pitch.com/?ref=bestdesignsoftware.io"
            image={'fluid' in data.pitchImage.childImageSharp ? data.pitchImage.childImageSharp.fluid : null}
            imagAlt="Screenshot of the design tool Pitch"
        />
    </Layout>
  )

  export default Highlight;

  export const query = graphql`
  query {
  
    canvaImage: file(relativePath: { eq: "canva_screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    figmaImage: file(relativePath: { eq: "figma_screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    
    uizardImage: file(relativePath: { eq: "uizard_screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }

    pitchImage: file(relativePath: { eq: "pitch_screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }

  }
`;
